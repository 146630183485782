import React, { useState, useEffect, useContext } from 'react'

// COMPONENTS
import UploadFile from './UploadFile/UploadFile'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PageDevicesContext } from 'contexts/PageDevicesContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// SAMPLE FILES
import FileSample from 'files/file_example_import_dialog.xlsx'

// SERVICES
import { postUploadFileApi } from 'services/devices/postUploadFileApi'
import { formatGetDevicesListApi } from 'services/devices/getDevicesListApi'

// STYLES
import useStyles from './importDialogUseStyles'

const ImportDialog = (props) => {
  const { 
    isImportDialog, 
    setIsImportDialog,
  } = props

  const { currentUser } = useContext(CurrentUserContext)

  const { 
    files,
    changeIsLoading,
    changeTableData,
  } = useContext(PageDevicesContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const [ errorMessage, setErrorMessage ] = useState(null)

  const caption = `You can import employees by uploading Excel table file with data. If file contains a header fields,\nthey will be detected automatically, otherwise, you can specify them manually.`

  const uploadFileUsingApi = async () => {
    changeIsLoading(true)

    const file = files[0]
    let formData = new FormData()
    formData.append('file', file)
    formData.append('withHeader', true)
    
    const response = await postUploadFileApi(currentUser['accessToken'], formData)
    if(response['error']) {
      changeToast({
        open: true,
        message: response['error'], 
        severity: 'error',
      })
    }
    else {
      setIsImportDialog(false)
      changeToast({
        open: true,
        message: 'Successfully uploading the file', 
        severity: 'success',
      })
      
      const data = await formatGetDevicesListApi(currentUser['accessToken'])
      if(data['error']) {
        changeToast({
          open: true,
          message: data['error'], 
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }
    changeIsLoading(false)
  }
  
  const onSaveButtonIsClicked = () => {
    if(!files || files['length'] === 0) {
      setErrorMessage('File must not be empty')
    }
    else {
      uploadFileUsingApi()
    }
  }

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setIsImportDialog(false)
    }
  }

  useEffect(() => {
    setErrorMessage(null)
	}, [files])

  return (
    <CustomDialog 
      open={Boolean(isImportDialog)}
      onClose={handleClose}
      scroll={'paper'}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Import
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* CAPTION */}
        {caption.split('\n').map((item, index) => (
          <Typography
            key={index}
            variant='subtitle1'
            className={classes['caption']}
          >
            {item}
          </Typography>
        ))}

        {/* FILE EXAMPLE */}
        <a
          href={FileSample}
          download='Example_File'
          target='_blank'
          rel='noreferrer'
        >
          <Typography
            variant='subtitle1'
            className={classes['link']}
          >
            Example File
          </Typography>
        </a>

        {/* UPLOAD FILE */}
        <UploadFile/>

        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => setIsImportDialog(false)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* OK BUTTON */}
        <Button onClick={onSaveButtonIsClicked}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default ImportDialog
