import React from 'react'

// COMPONENTS
import MainSection from './MainSection/MainSection'
import DeviceUpdate from './DeviceUpdate/DeviceUpdate'
import RightInfoAddButton from './RightInfoAddButton/RightInfoAddButton'

const StockOutRightInfo = () => {
  return (
    <>
      <MainSection/>
      <DeviceUpdate/>
      <RightInfoAddButton/>
    </>
  )
}

export default StockOutRightInfo
