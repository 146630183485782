// UTILS
import {
  getStringDateFromStringDate,
  getStringClockFromStringDate,
} from 'utils/formatStringDate'

export const getStaffListApi = async (inputToken) => {
  // const TAG = 'getStaffListApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/dashboard/staff`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${inputToken}`
    },
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}

const formatSummaryData = (inputSummaryData) => {
  const formattedSummaryData = inputSummaryData.map(summaryItem => {
    let formattedPunchList = []

    let i = 0
    while(i < summaryItem['punch']['length']) {
      const currentPunch = summaryItem['punch'][i] // 1ST
      const nextPunch = summaryItem['punch'][i+1] // 2ND

      // 1ST = FALSE
      if(!currentPunch['isClockIn']) {
        // 2ND IS AVAILABLE
        if(nextPunch) {
          // 1ST FALSE, 2ND TRUE
          if(nextPunch['isClockIn']) {
            formattedPunchList.push([ currentPunch, nextPunch ])
          }
          // 1ST FALSE, 2ND FALSE
          else if(!nextPunch['isClockIn']) {
            formattedPunchList.push([ currentPunch, null ], [ nextPunch, null ])
          }
          i = i + 2
        }
        // 2ND IS NOT AVAILABLE
        else if(!nextPunch) {
          formattedPunchList.push([ currentPunch, null ])
          i++
        }
      }
      // 1ST = TRUE
      else if(currentPunch['isClockIn']) {
        formattedPunchList.push([ null, currentPunch ])
        i++
      }
    }
    summaryItem['formattedPunchList'] = formattedPunchList
    return summaryItem
  })
  return formattedSummaryData
}

export const formatGetStaffListApi = async (inputToken) => {
  const rawListData = await getStaffListApi(inputToken)
  if(rawListData['error']) return rawListData
  else {
    const output = rawListData.map(item => {
      return {
        ...item,
        statusText: item['isApproved'] ? 'Approved' : 'Approve',
        createdAt: `${getStringDateFromStringDate(item['createdAt'])} ${getStringClockFromStringDate(item['createdAt'])}`,
        summary: formatSummaryData(item['summary']),
      }
    })
    return output
  }
}