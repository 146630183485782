import React, { useState, useContext } from 'react'

// COMPONENTS
import AddButton from 'components/AddButton/AddButton'
import SearchField from 'components/SearchField/SearchField'
import DialogAddOrEditCarModel from '../DialogAddOrEditCarModel/DialogAddOrEditCarModel'

// CONTEXTS
import { PageCarModelContext } from 'contexts/PageCarModelContext'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './carModelHeaderUseStyles'

const CarModelHeader = () => {
  const classes = useStyles()

  const { 
    search,
    changeSearch,
  } = useContext(PageCarModelContext)

  const [ dialogAddOrEditCarModel, setDialogAddOrEditCarModel ] = useState(false)

  return (
    <div className={classes['carModelHeaderRoot']}>
      {/* ADD BUTTON */}
      <AddButton
        className={classes['addIconButton']}
        onClick={() => setDialogAddOrEditCarModel(true)}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes['title']}
      >
        Car Model
      </Typography>

      {/* SEARCH TEXT FIELD */}
      <SearchField
        value={search}
        onChange={(event) => changeSearch(event['target']['value'])}
      />

      {/* DIALOG ADD OR EDIT CAR MODEL */}
      <DialogAddOrEditCarModel
        type='add'
        dialogAddOrEditCarModel={dialogAddOrEditCarModel}
        setDialogAddOrEditCarModel={setDialogAddOrEditCarModel}
      />
    </div>
  )
}

export default CarModelHeader
