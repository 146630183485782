export const deleteSalesOrderApi = async (
  inputToken, 
  inputSalesOrderId,
) => {
  // const TAG = 'deleteSalesOrderApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/sales-order/${inputSalesOrderId}`
  
  const response = await fetch(completeUrl, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
    },
  })

  // console.log(`${TAG}, response: `, response)
  return response
}