// SERVICES
import { formatGetAccessoriesListApiForStockOut } from 'services/accessories/getAccessoriesListApi'

// UTILS
import {
  getStringDateFromStringDate,
  getStringClockFromStringDate,
} from 'utils/formatStringDate'

export const getStockOutListApi = async (inputToken) => {
  // const TAG = 'getStockOutListApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/dashboard/stockout`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${inputToken}`
    },
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}

export const formatGetStockOutListApi = async (inputToken) => {
  const rawAccessoriesList = await formatGetAccessoriesListApiForStockOut(inputToken)
  const rawListData = await getStockOutListApi(inputToken)

  if(rawListData['error']) return rawListData
  else if(rawAccessoriesList['error']) return rawAccessoriesList
  else {
    const output = rawListData.map(item => {
      const accessoriesList = JSON.parse(JSON.stringify(rawAccessoriesList))

      for(let i = 0; i < item['accessories']['sockets']['length']; i++) {
        const socketItem = item['accessories']['sockets'][i]

        for(let j = 0; j < accessoriesList['length']; j++) {
          const accessoryItem = accessoriesList[j]
          
          if(socketItem['itemName'] === accessoryItem['name']) {
            accessoryItem['quantity'] = accessoryItem['quantity'] + socketItem['quantity']
          }
        }
      }

      let socketsObject = {}
      accessoriesList.forEach(item => {
        socketsObject[item['field']] = item['quantity']
      })

      return {
        ...item,
        date: `${getStringDateFromStringDate(item['doDate'])} ${getStringClockFromStringDate(item['doDate'])}`,
        salesPoint: item['salesPoint']['name'],
        totalImei: item['devices']['total'],
        formattedSockets: accessoriesList,
        ...socketsObject,
      }
    })
    return output
  }
}