export const postNewStockOutApi = async (
  inputToken,
  inputSalesPointId,
  inputDoDate,
  inputDevicesList,
  inputAccessoriesList,
) => {
  // const TAG = 'postNewStockOutApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/delivery-order/create`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      doNumber: '123456789',
      salesPointId: inputSalesPointId,
      doDate: inputDoDate,
      devices: inputDevicesList,
      accessories: inputAccessoriesList,
    }),
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}