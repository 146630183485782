import React from 'react'
import { useHistory } from 'react-router-dom'

// IMAGES
import IconSidebarToggle from 'images/icons/sidebar_toggle.svg'
import LogoLacakFullColor from 'images/logo/lacak_full_color.svg'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './toggleUseStyles'

const Toggle = (props) => {
  const { 
    isSidebarOpen, 
    setIsSidebarOpen,
  } = props

  const history = useHistory()

  const classes = useStyles()

  return (
    <div>
      <div className={classes['logoAndToogleContainer']}>
        {/* TOGGLE */}
        <img 
          src={IconSidebarToggle}
          alt=''
          className={
            isSidebarOpen ?
            classes['iconToggleSidebar'] :
            classes['iconToogleSidebarOnClose']
          }
          onClick={() => setIsSidebarOpen(current => !current)}
        />

        {/* LOGO */}
        {isSidebarOpen &&
        <img 
          src={LogoLacakFullColor}
          alt=''
          className={classes['companyLogo']}
          onClick={() => history.push('/dashboard')}
        />}
      </div>

      {/* CLIENT NAME TEXT */}
      {isSidebarOpen && <Typography
        variant='subtitle2'
        className={classes['clientName']}
      >
        Wuling Finance
      </Typography>}
    </div>
  )
}

export default Toggle
