import React, { useState, useEffect, useContext, useCallback } from 'react'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PagePlacesContext } from 'contexts/PagePlacesContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CONFIGURATIONS
import { 
  initialMapCenter,
  mapContainerStyle,
  circleOptions,
} from './dialogAddOrEditPlaceMapSettings'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomMenu from 'components/Custom/CustomMenu'
import CustomSlider from 'components/Custom/CustomSlider'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// GOOGLE MAPS
import {
  Circle,
  GoogleMap,
} from '@react-google-maps/api'

// MATERIAL UI CORES
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconArrowDropDown from '@material-ui/icons/ArrowDropDown'
import IconMap from '@material-ui/icons/Map'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// REACT GEOCODE
import Geocode from 'react-geocode'

// SERVICES
import { getPlacesListApi } from 'services/places/getPlacesListApi'
import { postNewPlaceApi } from 'services/places/postNewPlaceApi'
import { putUpdatePlaceApi } from 'services/places/putUpdatePlaceApi'

// STYLES
import useStyles from './dialogAddOrEditPlaceUseStyles'

// USE PLACES AUTOCOMPLETE
import usePlacesAutocomplete from 'use-places-autocomplete'

const AddPlacesDialog = (props) => {
  const { 
    type,
    dialogAddOrEditPlace, 
    setDialogAddOrEditPlace,
  } = props

  const { currentUser } = useContext(CurrentUserContext)

  const { 
    changeIsLoading,
    changeTableData,
  } = useContext(PagePlacesContext)

  const { changeToast } = useContext(PageAllContext)

  const {
    value: address,
    suggestions,
    setValue: setAddress,
    clearSuggestions,
  } = usePlacesAutocomplete()
  
  const classes = useStyles()

  const [ placeName, setPlaceName ] = useState(type === 'edit' ? dialogAddOrEditPlace['row']['name'] : (type === 'add' ? '' : ''))
  const [ selectedAddress, setSelectedAddress ] = useState(type === 'edit' ? dialogAddOrEditPlace['row']['address'] : (type === 'add' ? null : null))
  const [ radius, setRadius ] = useState(type === 'edit' ? dialogAddOrEditPlace['row']['radius'] : (type === 'add' ? 50 : 50))
  const [ errorMessage, setErrorMessage ] = useState(null)
  const [ isMapShown, setIsMapShown ] = useState(type === 'edit' ? true : (type === 'add' ? false : false))
  const [ map, setMap ] = useState(null)
  const [ mapCenter, setMapCenter ] = useState(
    type === 'edit' ?
    { lat: dialogAddOrEditPlace['row']['latitude'], lng: dialogAddOrEditPlace['row']['longitude']} :
    (type === 'add' ? initialMapCenter : initialMapCenter))
  const [ circle, setCircle ] = useState(null)
  const [ radiusMenuAnchor, setRadiusMenuAnchor ] = useState(false)

  const addressOptions = suggestions['data'].map((suggestion) => {
    const { structured_formatting: { main_text, secondary_text } } = suggestion
    return `${main_text}, ${secondary_text}`
  })

  const minRadius = 50
  const maxRadius = 1500

  const radiusOptions = [ 50, 150, 500, 1500 ]

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddOrEditPlace(false)
    }
  }

  const onFinishButtonIsClicked = async () => {
    if(placeName === '' || selectedAddress === '') setErrorMessage('Please fill all fields')
    else if(radius < 50 || radius > 1500) setErrorMessage('Radius must be in range of 50-1500 meters')
    else {
      changeIsLoading(true)

      let response
      if(type === 'edit') {
        response = await putUpdatePlaceApi(
          currentUser['accessToken'],
          dialogAddOrEditPlace['row']['id'],
          placeName,
          selectedAddress,
          circle['center'].lat(),
          circle['center'].lng(),
          radius,
        )
      }
      else if(type === 'add') {
        response = await postNewPlaceApi(
          currentUser['accessToken'],
          placeName,
          selectedAddress,
          circle['center'].lat(),
          circle['center'].lng(),
          radius,
        )
      }

      if(response['error']) {
        changeToast({
          open: true,
          message: response['error'], 
          severity: 'error',
        })
      }
      else {
        changeToast({
          open: true,
          message: `Successfully ${type}ing a ${type === 'add' ? 'new' : ''} place`, 
          severity: 'success',
        })
        
        const data = await getPlacesListApi(currentUser['accessToken'])
        // console.log(data)
        if(data['error']) {
          changeToast({
            open: true,
            message: data['error'], 
            severity: 'error',
          })
        }
        else {
          changeTableData(data)
        }
      }

      changeIsLoading(false)
      setDialogAddOrEditPlace(false)
    }
  }

  const onRadiusMenuItemIsClicked = (inputValue) => {
    setRadius(inputValue)
    setRadiusMenuAnchor(null)
  }

  const onAutocompleteChange = (inputEvent, inputNewValue) => {
    setSelectedAddress(inputNewValue)
    getLocationFromSelectedAddress(inputNewValue)
  }

  const getLocationFromSelectedAddress = (inputSelectedAddress) => {
    setAddress(inputSelectedAddress, false)
    clearSuggestions()

    Geocode.fromAddress(inputSelectedAddress)
    .then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        setIsMapShown(true)
        setMapCenter({lat, lng})
      },
      (error) => {
        console.error(error)
      }
    )
  }

  const circleOnLoad = useCallback((circle) => {
    setCircle(circle)
  }, [])

  const circleOnUnMount = useCallback((circle) => {
    setCircle(null)
  }, []) 

  const circleCenterChanged = () => {
    circle && getAddressFromLocation(circle['center'].lat(), circle['center'].lng())
  }
  
  const circleRadiusChanged = () => {
    circle && setRadius(parseInt(circle['radius']))
  }

  const getAddressFromLocation = (inputLatitude, inputLongitude) => {
    Geocode.fromLatLng(inputLatitude, inputLongitude)
    .then(
      (response) => {
        const formattedAddress = response.results[0].formatted_address
        setSelectedAddress(formattedAddress)
      },
      (error) => {
        console.error(error)
      }
    )
  }

  const onMapLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onMapUnmount = useCallback((map) => {
    setMap(null)
  }, [])

  useEffect(() => {
    setErrorMessage(null)
  }, [placeName, address, radius])

  useEffect(() => {
    if(map && circle) {
      const bounds = new window.google.maps.LatLngBounds()
      bounds.union(circle.getBounds())
      map.fitBounds(bounds)
      setMap(map)
    }

    return () => {}
  }, [circle && circle['center'].lat(),
      circle && circle['center'].lng(), 
      radius,
      mapCenter,
      address,
  ])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogAddOrEditPlace)}
      onClose={handleClose}
      className={classes['dialogRoot']}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        {type === 'add' && 'Add Place'}
        {type === 'edit' && 'Edit Place'}
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* PLACE NAME TEXT FIELD */}
        <CustomTextFieldSmall
          className={classes['textField']}
          // disabled={isLoading}
          label='Place Name'
          value={placeName}
          onChange={(event) => setPlaceName(event['target']['value'])}
        />

        {/* ADDRESS TEXT FIELD */}
        <Autocomplete
          options={addressOptions}
          getOptionLabel={(option) => option}
          value={selectedAddress}
          onChange={onAutocompleteChange}
          renderInput={(params) =>
            <CustomTextFieldSmall 
              {...params} 
              className={classes['textField']}
              // disabled={isLoading}
              label='Address'
              value={address}
              onChange={(event) => setAddress(event['target']['value'])}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment 
                    position='end' 
                    className={classes['iconMap']}
                    onClick={() => setIsMapShown(current => !current)}
                  >
                    <IconMap/>
                  </InputAdornment>
                ),
              }}
            />
          }
        />

        {/* GOOGLE MAPS */}
        <div>
          <GoogleMap
            mapContainerStyle={mapContainerStyle({display: isMapShown ? 'block' : 'none'})}
            center={mapCenter}
            zoom={5}
            onLoad={onMapLoad}
            onUnmount={onMapUnmount}
            options={{
              // SOURCE: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
              streetViewControl: false,
              scaleControl: false,
              mapTypeControl: false,
              // zoomControl: false,
            }}
          >
            {selectedAddress && <Circle
              center={mapCenter}
              radius={radius}
              options={circleOptions}
              onLoad={circleOnLoad}
              onUnmount={circleOnUnMount}
              // TODO: ON CENTER CHANGED SHOULD BE REPLACED BY ON DRAG OR ON DRAG END
              onCenterChanged={circleCenterChanged}
              onRadiusChanged={circleRadiusChanged}
            />}
          </GoogleMap>
        </div>

        {/* RAIDUS TEXT FIELD AND SLIDER */}
        <div className={classes['flexContainer']}>
          {/* RADIUS TEXT FIELD */}
          <CustomTextFieldSmall
            className={classes['numberTextField']}
            // disabled={isLoading}
            label='Radius'
            value={radius}
            type='number'
            onChange={(event) => 
              event['target']['value'] < 0 ?
              setRadius(0) :
              setRadius(event['target']['value'])
            }
            onClick={(event) => setRadiusMenuAnchor(event['currentTarget'])}
            InputProps={{
              endAdornment: (
                <InputAdornment 
                  position='end' 
                  className={
                    radiusMenuAnchor ?
                    classes['iconRadiusDropUp'] :
                    classes['iconRadiusDropDown']
                  }
                  onClick={(event) => setRadiusMenuAnchor(event['currentTarget'])}
                >
                  <IconArrowDropDown/>
                </InputAdornment>
              ),
            }}
          />

          {/* RADIUS OPTIONS */}
          <CustomMenu
            anchorEl={radiusMenuAnchor}
            keepMounted
            open={Boolean(radiusMenuAnchor)}
            onClose={() => setRadiusMenuAnchor(null)}
            classes={{ paper: classes['customMenu']}}
          >
            {radiusOptions.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => onRadiusMenuItemIsClicked(item)}
              >
                {`${item} meters`}
              </MenuItem>
            ))}
          </CustomMenu>

          {/* RADIUS SLIDER */}
          <CustomSlider
            className={classes['slider']}
            valueLabelDisplay='on'
            value={radius}
            onChange={(event, newValue) => setRadius(newValue)}
            min={minRadius}
            max={maxRadius}
          />
        </div>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => setDialogAddOrEditPlace(false)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* OK BUTTON */}
        <Button onClick={onFinishButtonIsClicked}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default AddPlacesDialog
