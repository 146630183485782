export const putUpdateReceiveShipmentApi = async (
  inputToken,
  inputDeliverOrderId,
  inputShipmentId,
  inputShippingNumber,
  inputFrom,
  inputSentBy,
  inputTo,
  inputSentAt,
  inputCarrierName,
  inputReceivedBy,
  inputReceivedAt,
) => {
  // const TAG = 'putUpdateReceiveShipmentApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/delivery-order/${inputDeliverOrderId}/shipment/${inputShipmentId}`
  
  const response = await fetch(completeUrl, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      shippingNumber: inputShippingNumber,
      from: inputFrom,
      sentBy: inputSentBy,
      to: inputTo,
      sentAt: inputSentAt,
      carrierName: inputCarrierName,
      receivedBy: inputReceivedBy,
      receivedAt: inputReceivedAt,
    }),
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}