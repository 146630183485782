export const postNewPlaceApi = async (
  inputToken,
  inputName,
  inputAddress,
  inputLatitude,
  inputLongitude,
  inputRadius,
) => {
  // const TAG = 'postNewPlaceApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/place/create`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: inputName,
      address: inputAddress,
      latitude: parseFloat(inputLatitude),
      longitude: parseFloat(inputLongitude),
      radius: parseFloat(inputRadius),
    }),
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}