import React, { useContext } from 'react'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PageSalesOrderContext } from 'contexts/PageSalesOrderContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// SERVICES
import { deleteSalesOrderApi } from 'services/salesOrder/deleteSalesOrderApi'
import { getSalesOrderListApi } from 'services/salesOrder/getSalesOrderListApi'

// STYLES
import useStyles from './dialogDeleteSalesOrderUseStyles'

const DialogDeleteSalesOrder = (props) => {
  const { 
    dialogDeleteSalesOrder, 
    setDialogDeleteSalesOrder, 
  } = props

  const { currentUser } = useContext(CurrentUserContext) 

  const { 
    changeIsLoading, 
    changeTableData, 
  } = useContext(PageSalesOrderContext)
  
  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogDeleteSalesOrder(false)
    }
  }

  const onDeleteButtonIsClicked = async () => {
    changeIsLoading(true)

    const response = await deleteSalesOrderApi(
      currentUser['accessToken'],
      dialogDeleteSalesOrder['id'],
    )
    // console.log(response)
    if(response['error']) {
      changeToast({
        open: true,
        message: response['error'], 
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: 'Successfully deleting a sales order item', 
        severity: 'success',
      })

      const data = await getSalesOrderListApi(currentUser['accessToken'])
      if(data['error']) {
        changeToast({
          open: true,
          message: data['error'], 
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false)
    setDialogDeleteSalesOrder(null)
  }

  return (
    <CustomDialog 
      open={Boolean(dialogDeleteSalesOrder)}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Delete Item
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        <Typography variant='subtitle2'>
          Are you sure you want to delete this record?
        </Typography>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CLOSE BUTTON */}
        <Button onClick={() => setDialogDeleteSalesOrder(null)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* FINISH BUTTON */}
        <Button onClick={() => onDeleteButtonIsClicked()}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Delete
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogDeleteSalesOrder
