import React from 'react'

// COMPONENTS
import RightInfoMainSection from '../RightInfoMainSection/RightInfoMainSection'

const SalesOrderRightInfo = () => {
  return (
    <>
      <RightInfoMainSection/>
    </>
  )
}

export default SalesOrderRightInfo
