import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PageStockOutContext } from 'contexts/PageStockOutContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// DATE
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'

// MATERIAL UI PICKERS
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers'

// SERVICES
import { getPlacesListApi } from 'services/places/getPlacesListApi'
import { getDevicesListApi } from 'services/devices/getDevicesListApi'
import { postNewStockOutApi } from 'services/stocktOut/postNewStockOutApi'
import { formatGetStockOutListApi } from 'services/stocktOut/getStockOutListApi'

// STYLES
import useStyles from './dialogAddShipmentUseStyles'

const DialogAddShipment = (props) => {
  const { 
    isDialogAddOpen, 
    setIsDialogAddOpen,
  } = props

  const { currentUser } = useContext(CurrentUserContext)

  const { 
    tableData,
    changeIsLoading, 
    changeTableData,
  } = useContext(PageStockOutContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const initialSocketList =
  tableData['length'] > 0 &&
  tableData[0]['formattedSockets'].map(item => {
    return {
      ...item,
      itemId: item['id'],
      quantity: 0,
    }
  })

  const [ activeStep, setActiveStep ] = useState(0)
  const [ selectedDate, setSelectedDate ] = useState(new Date())
  const [ salesPointList, setSalesPointList ] = useState([])
  const [ selectedSalesPoint, setSelectedSalesPoint ] = useState(null)
  const [ imeiList, setImeiList ] = useState([])
  const [ selectedImeiList, setSelectedImeiList ] = useState([])
  const [ socketList, setSocketList ] = useState(initialSocketList)
  const [ errorMessage, setErrorMessage ] = useState(null)
  const [ isDialogLoading, setIsDialogLoading ] = useState(false)

  const stepperList = [
    'Basic Info',
    'Accessories',
  ]

  const fetchGetPlacesListApi = async () => {
    setIsDialogLoading(true)

    const data = await getPlacesListApi(currentUser['accessToken'])
    // console.log(data)
    if(data['error']) {
      setErrorMessage(data['error'])
    }
    else {
      setSalesPointList(data)
    }

    setIsDialogLoading(false)
  }

  const fetchGetDevicesListApi = async () => {
    setIsDialogLoading(true)

    const data = await getDevicesListApi(currentUser['accessToken'])
    // console.log(data)
    if(data['error']) {
      setErrorMessage(data['error'])
    }
    else {
      const newData = data.map(item => {
        return {
          stockId: item['id'],
          quantity: 1,
          imei: item['deviceInfo']['imei'],
        }
      })
      setImeiList(newData)
    }

    setIsDialogLoading(false)
  }

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setIsDialogAddOpen(false)
    }
  }

  const fetchPostNewStockOutApi = async () => {
    changeIsLoading(true)

    const response = await postNewStockOutApi(
      currentUser['accessToken'],
      selectedSalesPoint['id'],
      selectedDate,
      selectedImeiList,
      socketList,
    )

    if(response['error']) {
      changeToast({
        open: true,
        message: response['error'], 
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: 'Successfully adding a new stock out item', 
        severity: 'success',
      })

      const data = await formatGetStockOutListApi(currentUser['accessToken'])
      // console.log(data)
      if(data['error']) {
        changeToast({
          open: true,
          message: data['error'], 
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false)
    setIsDialogAddOpen(false)
  }

  const onSaveButtonIsClicked = () => {
    if(activeStep === 0) {
      if(
        selectedDate === '' || !selectedDate ||
        selectedSalesPoint === '' || !selectedSalesPoint || selectedImeiList['length'] === 0
      ) {
        setErrorMessage('Please fill all fields')
      }
      else {
        setActiveStep(current => current + 1)
      }
    }
    else if(activeStep === 1) {
      let showErorrMessage = false
      
      socketList.forEach(item => {
        if(item['quantity'] === '' || Number.isNaN(item['quantity'])) {
          showErorrMessage = true
        }
      })
      
      if(showErorrMessage) {
        setErrorMessage('Please fill all fields')
      }
      else {
        fetchPostNewStockOutApi()
      }
    }
  }

  const onSocketTextFieldChange = (inputValue, inputIndex) => {
    let newSocketList = [...socketList]
    
    inputValue < 0 ?
    newSocketList[inputIndex]['quantity'] = 0 :
    newSocketList[inputIndex]['quantity'] = parseInt(inputValue)
    
    setSocketList(newSocketList)
  }

  useEffect(() => {
    fetchGetPlacesListApi()
    fetchGetDevicesListApi()
    
    return () => {
      fetchGetPlacesListApi()
      fetchGetDevicesListApi()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps  

  useEffect(() => {
    setErrorMessage(null)
  }, [
    selectedDate,
    selectedSalesPoint,
    selectedImeiList,
    socketList,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={isDialogAddOpen}
      onClose={handleClose}
    >
      {isDialogLoading ?
      <CustomCircularProgress/> :
      <>
        {/* DIALOG TITLE */}
        <CustomDialogTitle>
          Add Shipment
        </CustomDialogTitle>

        {/* DIALOG CONTENT */}
        <CustomDialogContent>
          {/* STEPPER */}
          <Stepper 
            activeStep={activeStep} 
            alternativeLabel
          >
            {stepperList.map((item, index) => (
              <Step key={index}>
                <StepLabel>{item}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* ERROR MESSAGE */}
          {errorMessage && 
          <Alert 
            variant='filled'
            severity='error' 
            className={classes['errorMessage']}
          >
            {errorMessage}
          </Alert>}

          {/* DATE PICKER */}
          {activeStep === 0 &&
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className={`${classes['textField']} ${classes['datePickerTextField']}`}
              animateYearScrolling
              label='Date'
              format='dd/MM/yyyy'
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
            />
          </MuiPickersUtilsProvider>}

          {/* SALES POINT AUTOCOMPLETE*/}
          {activeStep === 0 &&
          <Autocomplete
            value={selectedSalesPoint}
            onChange={(event, newValue) => setSelectedSalesPoint(newValue)}
            options={salesPointList}
            getOptionLabel={(option) => option['name']}
            renderInput={(params) => (
              <CustomTextFieldSmall 
                className={classes['textField']}
                {...params} 
                label='Sales Point'
              />
            )}
          />}

          {/* IMEI AUTOCOMPLETE */}
          {activeStep === 0 &&
          <Autocomplete
            className={classes['autocompleteChip']}
            value={selectedImeiList}
            onChange={(event, newValue) => setSelectedImeiList(newValue)}
            multiple
            limitTags={3}
            options={imeiList}
            getOptionLabel={(option) => option['imei']}
            renderInput={(params) => (
              <CustomTextFieldSmall
                className={classes['textField']}
                {...params}
                variant='standard'
                label='IMEI'
              />
            )}
          />}

          {/* ALL SOCKETS */}
          {activeStep === 1 &&
          initialSocketList.map((item, index) => (
            <CustomTextFieldSmall 
              key={index}
              className={classes['textField']}
              label={item['name']} 
              value={socketList[index]['quantity']}
              type='number'
              onChange={(event) => onSocketTextFieldChange(event['target']['value'], index)}
            />
          ))}
        </CustomDialogContent>

        {/* DIALOG ACTIONS */}
        <CustomDialogActions>
          {/* PREVIOUS BUTTON */}
          {activeStep === 1 &&
          <Button 
            className={`${classes['cancelButton']} ${classes['previousButton']}`}
            onClick={() => setActiveStep(current => current - 1)}
          >
            <Typography
              variant='subtitle2'
              className={classes['cancelText']}
            >
              Prev
            </Typography>
          </Button>}

          {/* CANCEL BUTTON */}
          <Button 
            className={classes['cancelButton']}
            onClick={() => setIsDialogAddOpen(false)}
          >
            <Typography
              variant='subtitle2'
              className={classes['cancelText']}
            >
              Cancel
            </Typography>
          </Button>

          {/* OK BUTTON */}
          <Button 
            className={classes['okButton']}
            onClick={() => onSaveButtonIsClicked()}
          >
            <Typography
              variant='subtitle2'
              className={classes['okText']}
            >
              {activeStep === 0 && 'Next'}
              {activeStep === 1 && 'Save'}
            </Typography>
          </Button>
        </CustomDialogActions>
      </>}
    </CustomDialog>
  )
}

export default DialogAddShipment
