import React, { useState, useEffect, useContext, useRef } from 'react'

// COMPONENTS
import DialogAddOrEditPlace from '../DialogAddOrEditPlace/DialogAddOrEditPlace'
import DialogDeletePlace from '../DialogDeletePlace/DialogDeletePlace'

// CONTEXTS
import { PagePlacesContext } from 'contexts/PagePlacesContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'

// MATERIAL UI ICONS
import IconDelete from '@material-ui/icons/Delete'
import IconPencil from '@material-ui/icons/Create'

// STYLES
import useStyles from './placesTableUseStyles'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'

const PlacesTable = () => {
  const { 
    selectedRowList,
    changeSelectedRowList,
    search,
    tableData,
    changeIsRightContentOpen,
  } = useContext(PagePlacesContext)

  const classes = useStyles()

  const [ pageSize, setPageSize ] = useState(10)
  const [ filteredData, setFilteredData ] = useState([])
  const [ hovered, setHovered ] = useState(null)
  const [ dialogAddOrEditPlace, setDialogAddOrEditPlace ] = useState(false)
  const [ dialogDeletePlace, setDialogDeletePlace ] = useState(null)

  const cellClickRef = useRef(null)

  const columns = [
    {
      field: 'name',
      headerName: 'Place Name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 2,
      minWidth: 700,
    },
    {
      field: '',
      headerName: '',
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params['id'] === hovered &&
        <div className={classes['actionContainer']}>
          <IconButton onClick={() => setDialogAddOrEditPlace(params)}>
            <IconPencil className={classes['actionIcon']}/>
          </IconButton>
          <IconButton onClick={() => setDialogDeletePlace(params)}>
            <IconDelete className={classes['actionIcon']}/>
          </IconButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if(search !== '') {
      const filteredData = tableData.filter(item =>
        item['name'].toLowerCase().includes(search.toLowerCase()) ||
        item['address'].toLowerCase().includes(search.toLowerCase())
      )
      setFilteredData(filteredData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* DATA GRID */}
      <CustomDataGrid 
        rows={search === '' ? tableData : filteredData} 
        columns={columns}
        onRowEnter={(params, event) => setHovered(params['row']['id'])}
        onRowLeave={(params, event) => setHovered(null)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        selectionModel={selectedRowList}
        onCellClick={() => cellClickRef['current'] = true}
        onSelectionModelChange={(model) =>
          onSelectionModelChange(
            model, 
            cellClickRef, 
            selectedRowList, 
            changeSelectedRowList,
            changeIsRightContentOpen,
          )
        }
      />

      {/* DIALOG EDIT PLACE */}
      {dialogAddOrEditPlace &&
      <DialogAddOrEditPlace
        type='edit'
        dialogAddOrEditPlace={dialogAddOrEditPlace}
        setDialogAddOrEditPlace={setDialogAddOrEditPlace}
      />}

      {/* DIALOG DELETE PLACE */}
      <DialogDeletePlace
        dialogDeletePlace={dialogDeletePlace}
        setDialogDeletePlace={setDialogDeletePlace}
      />
    </>
  )
}

export default PlacesTable
