import React from 'react'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './dialogDeleteDeviceUseStyles'

const DialogDelete = (props) => {
  const { 
    dialogDelete, 
    setDialogDelete,
  } = props

  const classes = useStyles()

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogDelete(false)
    }
  }

  return (
    <CustomDialog 
      open={Boolean(dialogDelete)}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Delete Record
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        <Typography variant='subtitle2'>
          Are you sure you want to delete this record?
        </Typography>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CLOSE BUTTON */}
        <Button onClick={() => setDialogDelete(null)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* FINISH BUTTON */}
        <Button onClick={() => setDialogDelete(null)}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Delete
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogDelete
