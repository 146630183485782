import React, { useEffect, useContext } from 'react'

// COMPONENTS (SORTED BY ORDER)
import PlacesHeader from './PlacesHeader/PlacesHeader'
import PlacesTable from './PlacesTable/PlacesTable'
import RightInfo from './RightInfo/RightInfo'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PagePlacesContext } from 'contexts/PagePlacesContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'

// SERVICES
import { getPlacesListApi } from 'services/places/getPlacesListApi'

// STYLES
import useStyles from './placesUseStyles'

const Places = () => {
  const { currentUser } = useContext(CurrentUserContext)

  const classes = useStyles()

  const { 
    isRightContentOpen,
    isLoading,
    changeIsLoading,
    changeTableData,
  } = useContext(PagePlacesContext)
  
  const { changeToast } = useContext(PageAllContext)

  const fetchGetPlacesListApi = async () => {
    changeIsLoading(true)
    const data = await getPlacesListApi(currentUser['accessToken'])
    // console.log(data)
    if(data['error']) {
      changeToast({
        open: true,
        message: data['error'], 
        severity: 'error',
      })
    }
    else {
      changeTableData(data)
    }
    changeIsLoading(false)
  }

  useEffect(() => {
    fetchGetPlacesListApi()

    return () => fetchGetPlacesListApi()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['placesRoot']}>      
      {isLoading ?
      <CustomCircularProgress/> :
      <>
        {/* PLACES HEADER */}
        <PlacesHeader/>

        <div
          className={
            isRightContentOpen ?
            classes['cardsContainer'] :
            `${classes['cardsContainer']} ${classes['cardsContainerFull']}`
          }
        >
          {/* LEFT CONTENT */}
          <div 
            className={classes['cardLeft']}
          >
            <PlacesTable/>
          </div>
          
          {/* RIGHT CONTENT */}
          <div
            className={
              isRightContentOpen ?
              `${classes['cardRight']}` :
              `${classes['cardRight']} ${classes['cardRightHide']}`
            }
          >
            <RightInfo/>
          </div>
        </div>
      </>}
    </div>
  )
}

export default Places
