export const postNewSalesOrderApi = async (
  inputToken,
  inputSellerPlaceId,
  inputInstallationPlaceId,
  inputContractNumber,
  inputMachineNumber,
  inputCustomerName,
  inputBpkbCustomerName,
  inputCarModelId,
  inputChassisNumber,
  inputAdminName,
  inputInstallationDate,
  inputPicName,
  inputPicPhoneNumber,
) => {
  // const TAG = 'postNewSalesOrderApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/sales-order/`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sellerPlaceId: inputSellerPlaceId,
      installationPlaceId: inputInstallationPlaceId,
      contractNumber: inputContractNumber,
      machineNumber: inputMachineNumber,
      customerName: inputCustomerName,
      bpkbCustomerName: inputBpkbCustomerName,
      carModelId: inputCarModelId,
      chassisNumber: inputChassisNumber,
      adminName: inputAdminName,
      installmentDate: inputInstallationDate,
      picName: inputPicName,
      picPhoneNumber: inputPicPhoneNumber,
    }),
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}