import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PageDevicesContext } from 'contexts/PageDevicesContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// SERVICES
import { formatGetDevicesListApi } from 'services/devices/getDevicesListApi'
import { postNewDeviceApi } from 'services/devices/postNewDeviceApi'

// STYLES
import useStyles from './addNewDialogUseStyles'

const AddNewDialog = (props) => {
  const { 
    isAddNewDialog,
    setIsAddNewDialog,
  } = props

  const { currentUser } = useContext(CurrentUserContext)

  const { 
    changeIsLoading,
    changeTableData,
  } = useContext(PageDevicesContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const [ simNumber, setSimNumber ] = useState('')
  const [ imei, setImei ] = useState('')
  // const [ serialNumber, setSerialNumber ] = useState('')
  const [ errorMessage, setErrorMessage ] = useState(null)

  const fetchPostNewDeviceApi = async () => {
    changeIsLoading(true)

    const response = await postNewDeviceApi(
      currentUser['accessToken'],
      imei,
      simNumber,
      // serialNumber,
      '000000000000',
    )

    if(response['error']) {
      changeToast({
        open: true,
        message: response['error'], 
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: 'Sucessfully adding a new device', 
        severity: 'success',
      })

      const data = await formatGetDevicesListApi(currentUser['accessToken'])
      // console.log(data)
      if(data['error']) {
        changeToast({
          open: true,
          message: data['error'], 
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false) 
    setIsAddNewDialog(false)
  }
  
  const onFinishButtonIsClicked = () => {
    if(simNumber === '' || imei === '') {
      setErrorMessage('Please fill all fields')
    }
    else {
      fetchPostNewDeviceApi()
    }
  }

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setIsAddNewDialog(false)
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [simNumber, imei]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(isAddNewDialog)}
      onClose={handleClose}
      scroll={'paper'}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Add New
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* SIM NUMBER TEXT FIELD */}
        <CustomTextFieldSmall
          className={classes['textField']}
          // disabled={isLoading}
          label='Sim Number'
          value={simNumber}
          onChange={(event) => setSimNumber(event['target']['value'])}
        />

        {/* IMEI TEXT FIELD */}
        <CustomTextFieldSmall
          className={classes['textField']}
          // disabled={isLoading}
          label='IMEI'
          value={imei}
          onChange={(event) => setImei(event['target']['value'])}
        />

        {/* SERIAL NUMBER FIELD */}
        {/* <CustomTextFieldSmall
          className={classes['textField']}
          // disabled={isLoading}
          label='Serial Number'
          value={serialNumber}
          onChange={(event) => setSerialNumber(event['target']['value'])}
        /> */}
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => setIsAddNewDialog(false)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* OK BUTTON */}
        <Button onClick={onFinishButtonIsClicked}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default AddNewDialog
