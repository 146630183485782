import React, { useEffect, useContext } from 'react'

// COMPONENTS
import CarModelHeader from '../CarModel/CarModelHeader/CarModelHeader'
import CarModelTable from '../CarModel/CarModelTable/CarModelTable'

// CONTEXTS
import { PageCarModelContext } from 'contexts/PageCarModelContext'

// DATA
import carModelData from './carModelData'

// STYLES
import useStyles from './carModelUseStyles'

const CarModel = () => {
  const classes = useStyles()

  const { changeTableData } = useContext(PageCarModelContext)

  useEffect(() => {
    changeTableData(carModelData)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['carModelRoot']}>
      {/* HEADER */}
      <CarModelHeader/>

      <div className={classes['contentCard']}>
        {/* TABLE */}
        <CarModelTable/>
      </div>
    </div>
  )
}

export default CarModel
