import React, { useState, useEffect } from 'react'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MUI ICONS
import IconDirectionsCar from '@mui/icons-material/DirectionsCar'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// STYLES
import useStyles from './dialogAddOrEditCarModelUseStyles'

const DialogAddOrEditCarModel = (props) => {
  const { 
    type,
    dialogAddOrEditCarModel, 
    setDialogAddOrEditCarModel, 
  } = props

  const classes = useStyles()

  const [ carModelName, setCarModelName ] = useState('')
  const [ errorMessage, setErrorMessage ] = useState(null)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddOrEditCarModel(false)
    }
  }

  const onSaveButtonIsClicked = () => {
    if(carModelName === '') {
      setErrorMessage('Please fill all fields')
    }
    else {
      setDialogAddOrEditCarModel(null)
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [carModelName]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogAddOrEditCarModel)}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        {type === 'add' && 'Add a New Car Model'}
        {type === 'edit' && 'Edit a Car Model'}
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* CAR MODEL NAME */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconDirectionsCar className={classes['icon']}/>
          <CustomTextFieldSmall 
            className={classes['textField']}
            label='Car Model Name' 
            value={carModelName}
            type='text'
            onChange={(event) => setCarModelName(event['target']['value'])}
          />
        </div>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CLOSE BUTTON */}
        <Button onClick={() => setDialogAddOrEditCarModel(null)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* FINISH BUTTON */}
        <Button onClick={() => onSaveButtonIsClicked()}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogAddOrEditCarModel
