import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PageStockOutContext } from 'contexts/PageStockOutContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// DATE
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// MATERIAL UI PICKERS
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers'

// SERVICES
import { postNewDeliveryShipmentApi } from 'services/stocktOut/postNewDeliveryShipmentApi'
import { formatGetStockOutListApi } from 'services/stocktOut/getStockOutListApi'

// STYLES
import useStyles from './dialogDeliveryUseStyles'

const DialogDelivery = (props) => {
  const {
    dialogDelivery,
    setDialogDelivery,
    setIsDialogQuickActionOpen,
  } = props

  const { currentUser } = useContext(CurrentUserContext)

  const { 
    selectedRowList, 
    tableData, 
    changeTableData,
    changeIsLoading,
  } = useContext(PageStockOutContext)

  const { changeToast } = useContext(PageAllContext)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  const classes = useStyles()

  const [ selectedDate, setSelectedDate ] = useState(new Date())
  const [ selectedCarrier, setSelectedCarrier ] = useState('')
  const [ receiptNumber, setReceiptNumber ] = useState('')
  const [ fromName, setFromName ] = useState('')
  const [ toName, setToName ] = useState('')
  const [ errorMessage, setErrorMessage ] = useState(null)

  const carrierList = [
    'JNE',
    'TIKI',
    'SiCepat',
    'Gosend',
  ]

  const fromToList = [
    'Lacak',
    'MIT',
    'Robicom',
    'Wuling',
  ]

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogDelivery(false)
    }
  }

  const fetchPostDeliveryShipmentApi = async () => {
    changeIsLoading(true)

    const response = await postNewDeliveryShipmentApi(
      currentUser['accessToken'],
      selectedData[0]['id'],
      receiptNumber,
      fromName,
      '',
      toName,
      selectedDate,
      selectedCarrier,
    )

    if(response['error']) {
      changeToast({
        open: true,
        message: response['error'], 
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: 'Successfully adding a new delivery item', 
        severity: 'success',
      })

      const data = await formatGetStockOutListApi(currentUser['accessToken'])
      // console.log(data)
      if(data['error']) {
        changeToast({
          open: true,
          message: data['error'], 
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false)
    setDialogDelivery(false)
  }

  const onSaveButtonIsClicked = () => {
    if(
      selectedDate === '' || selectedCarrier === '' || receiptNumber === '' || 
      fromName === '' || toName === ''
    ) {
      setErrorMessage('Please fill all fields')
    }
    else {
      fetchPostDeliveryShipmentApi()
    }
  }

  const onCancelButtonIsClicked = () => {
    setDialogDelivery(false)
    setIsDialogQuickActionOpen(true)
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [selectedCarrier, receiptNumber]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogDelivery)}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Add Delivery
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* DATE PICKER */}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            className={`${classes['textField']} ${classes['datePickerTextField']}`}
            animateYearScrolling
            label='Date'
            format='dd/MM/yyyy hh:mm:ss aa'
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
        </MuiPickersUtilsProvider>

        {/* SELECT CARRIER */}
        <FormControl className={classes['textField']}>
          <InputLabel id='select-carrier'>
            Carrier
          </InputLabel>
          <Select
            labelId='select-carrier'
            id='select-carrier'
            value={selectedCarrier}
            onChange={(event) => setSelectedCarrier(event['target']['value'])}
          >
            {carrierList.map((item, index) => (
              <MenuItem
                key={index} 
                value={item}
                className={classes['menuItem']}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* RECEIPT NUMBER */}
        <CustomTextFieldSmall 
          className={classes['textField']}
          label='No. Resi' 
          value={receiptNumber}
          type='text'
          onChange={(event) => setReceiptNumber(event['target']['value'])}
        />

        <div className={classes['fromToContainer']}>
          {/* SELECT FROM */}
          <FormControl className={`${classes['textField']} ${classes['textFieldHalf']}`}>
            <InputLabel id='select-from'>
              From
            </InputLabel>
            <Select
              labelId='select-from'
              id='select-from'
              value={fromName}
              onChange={(event) => setFromName(event['target']['value'])}
            >
              {fromToList.map((item, index) => (
                <MenuItem
                  key={index} 
                  value={item}
                  className={classes['menuItem']}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* SELECT FROM */}
          <FormControl className={`${classes['textField']} ${classes['textFieldHalf']}`}>
            <InputLabel id='select-from'>
              To
            </InputLabel>
            <Select
              labelId='select-from'
              id='select-from'
              value={toName}
              onChange={(event) => setToName(event['target']['value'])}
            >
              {fromToList.map((item, index) => (
                <MenuItem
                  key={index} 
                  value={item}
                  className={classes['menuItem']}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => onCancelButtonIsClicked()}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* OK BUTTON */}
        <Button onClick={() => onSaveButtonIsClicked()}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogDelivery
