export const getAccessoriesListApi = async (inputToken) => {
  // const TAG = 'getAccessoriesListApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/item/list?type=ACCESSORY`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${inputToken}`
    },
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}

export const formatGetAccessoriesListApiForStockOut = async (inputToken) => {
  const rawAccessoriesList = await getAccessoriesListApi(inputToken)

  if(rawAccessoriesList['error']) return rawAccessoriesList
  else {
    const output = rawAccessoriesList.map(item => {
      return {
        id: item['id'],
        name: item['name'],
        field: item['name'].toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (match, character) => character.toUpperCase()),
        quantity: 0,
      }
    })
    return output
  }
}