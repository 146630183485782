export const putUpdatePlaceApi = async (
  inputToken,
  inputPlaceId,
  inputName,
  inputAddress,
  inputLatitude,
  inputLongitude,
  inputRadius,
) => {
  // const TAG = 'putUpdatePlaceApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/place/update`
  
  const response = await fetch(completeUrl, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      placeId: inputPlaceId,
      newName: inputName,
      newAddress: inputAddress,
      newLatitude: inputLatitude,
      newLongitude: inputLongitude,
      newRadius: inputRadius,
    }),
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}