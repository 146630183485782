export const deleteAccessoryApi = async (
  inputToken, 
  inputItemId,
) => {
  // const TAG = 'deleteAccessoryApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/item/delete/${inputItemId}`
  
  const response = await fetch(completeUrl, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
    },
  })

  // console.log(`${TAG}, response: `, response)
  return response
}