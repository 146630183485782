import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    '& + .pac-container': {
      zIndex: theme.zIndex.modal + 1
    },
  },
  errorMessage: {
    marginBottom: 25,
  },
  textField: {
    width: '100%',
    minWidth: 500,
		marginBottom: 25,
  },
  numberTextField: {
    width: '50%',
    marginRight: 20,
	},
  iconRadiusDropDown: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  iconRadiusDropUp: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  customMenu: {
    width: 266,
    boxShadow: `0px 10px 10px ${colors['boxShadowDark']}`,
  },
  iconMap: {
    color: colors['slategray'],
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
  },
  flexContainer: {
    display: 'flex',
  },
  slider: {
    width: '50%',
    alignSelf: 'flex-end',
    marginLeft: 20,
    marginTop: 30,
  },
  okText: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
  cancelText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
}))

export default useStyles