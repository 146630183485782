import React, { useState } from 'react'

// COMPONENTS (SORTED BY ORDER)
import AddNewDialog from '../AddNewDialog/AddNewDialog'
import ImportDialog from '../ImportDialog/ImportDialog'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'

// IMAGES
import IconAddNew from 'images/icons/quick_action_dialog_add_new.svg'
import IconImport from 'images/icons/quick_action_dialog_import.svg'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './quickActionDialogUseStyles'
 
const QuickActionDialog = (props) => {
  const { 
    isQuickActionDialog, 
    setIsQuickActionDialog,
  } = props

  const classes = useStyles()

  const [ isAddNewDialog, setIsAddNewDialog ] = useState(false)
  const [ isImportDialog, setIsImportDialog ] = useState(false)

  const quickActionList = [
    {
      icon: IconAddNew,
      title: 'Add New',
      caption: 'Create a new device',
    },
    {
      icon: IconImport,
      title: 'Import',
      caption: 'Import items via excel file',
    },
  ]

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setIsQuickActionDialog(false)
    }
  }

  const onItemIsClicked = (inputIndex) => {
    if(inputIndex === 0) {
      setIsQuickActionDialog(false)
      setIsAddNewDialog(true)
    }
    else if(inputIndex === 1) {
      setIsQuickActionDialog(false)
      setIsImportDialog(true)
    }
   }

  return (
    <>
      <CustomDialog 
        open={Boolean(isQuickActionDialog)}
        onClose={handleClose}
        scroll={'paper'}
      >
        {/* DIALOG TITLE */}
        <CustomDialogTitle>
          Quick Action
        </CustomDialogTitle>
        
        {/* DIALOG CONTENT */}
        <CustomDialogContent>
          {/* MENU LIST */}
          {quickActionList.map((item, index) => (
            <div
              key={index}
              className={classes['itemListContainer']}
              onClick={() => onItemIsClicked(index)}
            >
              {/* ICON */}
              <img 
                src={item['icon']} 
                alt='' 
                className={classes['icon']}
              />

              {/* TEXTS */}
              <div>
                {/* ITEM TITLE */}
                <Typography
                  variant='subtitle1'
                  className={classes['itemTitle']}
                >
                  {item['title']}
                </Typography>

                {/* ITEM CAPTION */}
                <Typography
                  variant='subtitle2'
                  className={classes['itemCaption']}
                >
                  {item['caption']}
                </Typography>
              </div>
            </div>
          ))}
        </CustomDialogContent>

        {/* DIALOG ACTIONS */}
        <CustomDialogActions>
          {/* CANCEL BUTTON */}
          <Button onClick={() => setIsQuickActionDialog(false)}>
            <Typography
              variant='subtitle2'
              className={classes['cancelText']}
            >
              Cancel
            </Typography>
          </Button>
        </CustomDialogActions>
      </CustomDialog>

      {/* ADD NEW DIALOG */}
      <AddNewDialog
        isAddNewDialog={isAddNewDialog}
        setIsAddNewDialog={setIsAddNewDialog}
      />

      {/* IMPORT DIALOG */}
      <ImportDialog
        isImportDialog={isImportDialog}
        setIsImportDialog={setIsImportDialog}
      />
    </>
  )
}

export default QuickActionDialog
