// IMAGES
import IconDashboardGray from 'images/icons/sidebar_dashboard_gray.svg'
import IconDashboardBlue from 'images/icons/sidebar_dashboard_blue.svg'
import IconItemsGray from 'images/icons/sidebar_item_gray.svg'
import IconItemsBlue from 'images/icons/sidebar_item_blue.svg'
import IconPlacesGray from 'images/icons/sidebar_places_gray.svg'
import IconPlacesBlue from 'images/icons/sidebar_places_blue.svg'
import IconStockOutGray from 'images/icons/sidebar_stockout_gray.svg'
import IconStockOutBlue from 'images/icons/sidebar_stockout_blue.svg'
import IconSalesOrderGray from 'images/icons/sidebar_salesorder_gray.svg'
import IconSalesOrderBlue from 'images/icons/sidebar_salesorder_blue.svg'
import IconTasksGray from 'images/icons/sidebar_tasks_gray.svg'
import IconTasksBlue from 'images/icons/sidebar_tasks_blue.svg'
import IconSubmissionsGray from 'images/icons/sidebar_submissions_gray.svg'
import IconSubmissionsBlue from 'images/icons/sidebar_submissions_blue.svg'
import IconStaffsGray from 'images/icons/sidebar_staffs_gray.svg'
import IconStaffsBlue from 'images/icons/sidebar_staffs_blue.svg'
import IconProfileGray from 'images/icons/sidebar_profile_gray.svg'
import IconProfileBlue from 'images/icons/sidebar_profile_blue.svg'

const pageListData = [
  {
    type: 'single',
    name: 'Dashboard',
    iconDefault: IconDashboardGray,
    iconSelected: IconDashboardBlue,
  },
  {
    type: 'collection',
    name: 'Items',
    children: [
      'Devices',
      'Accessories',
      'Car Model',
    ],
    iconDefault: IconItemsGray,
    iconSelected: IconItemsBlue,
  },
  {
    type: 'single',
    name: 'Places',
    iconDefault: IconPlacesGray,
    iconSelected: IconPlacesBlue,
  },
  {
    type: 'single',
    name: 'Stock Out',
    iconDefault: IconStockOutGray,
    iconSelected: IconStockOutBlue,
  },
  {
    type: 'single',
    name: 'Sales Order',
    iconDefault: IconSalesOrderGray,
    iconSelected: IconSalesOrderBlue,
  },
  {
    type: 'collection',
    name: 'Tasks',
    children: [
      'Pickup Request',
      'Installation Request',
    ],
    iconDefault: IconTasksGray,
    iconSelected: IconTasksBlue,
  },
  {
    type: 'collection',
    name: 'Submissions',
    children: [
      'Pickup',
      'Installation',
    ],
    iconDefault: IconSubmissionsGray,
    iconSelected: IconSubmissionsBlue,
  },
  {
    type: 'single',
    name: 'Staff',
    iconDefault: IconStaffsGray,
    iconSelected: IconStaffsBlue,
  },
  {
    type: 'single',
    name: 'Profile',
    iconDefault: IconProfileGray,
    iconSelected: IconProfileBlue,
  },
]

export default pageListData