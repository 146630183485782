import React, { useState, useContext } from 'react'

// CONTEXTS
import { PageSalesOrderContext } from 'contexts/PageSalesOrderContext'

// IMAGES
import IconExpand from 'images/icons/staff_expand.svg'

// MATERIAL UI CORES
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconAccountBox from '@mui/icons-material/AccountBox'
import IconTextSnippet from '@mui/icons-material/TextSnippet'
import IconChromeReaderMode from '@mui/icons-material/ChromeReaderMode'
import IconDirectionsCar from '@mui/icons-material/DirectionsCar'
import IconGarage from '@mui/icons-material/Garage'
import IconBallot from '@mui/icons-material/Ballot'
import IconPlace from '@mui/icons-material/Place'
import IconPinDrop from '@mui/icons-material/PinDrop'
import IconContacts from '@mui/icons-material/Contacts'
import IconCall from '@mui/icons-material/Call'
import IconInsertInvitation from '@mui/icons-material/InsertInvitation'
import IconPersonPin from '@mui/icons-material/PersonPin'
import IconDateRange from '@mui/icons-material/DateRange'
import IconReceipt from '@mui/icons-material/Receipt'

// STYLES
import useStyles from './rightInfoMainSectionUseStyles'

const RightInfoMainSection = () => {
  const classes = useStyles()

  const { 
    selectedRowList, 
    tableData, 
  } = useContext(PageSalesOrderContext)

  const [ isMainContentExpand, setIsMainContentExpand ] = useState(false)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  const data = selectedData['length'] > 0 && [
    {
      subtitle: 'Customer Name',
      content: selectedData[0]['customerName'],
      icon: <IconAccountBox className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Contract Number',
      content: selectedData[0]['contractNumber'],
      icon: <IconTextSnippet className={classes['iconForText']}/>,
    },
    {
      subtitle: 'BPKB Name',
      content: selectedData[0]['bpkbCustomerName'],
      icon: <IconChromeReaderMode className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Car Model',
      content: selectedData[0]['carModel']['name'],
      icon: <IconDirectionsCar className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Chassis Number',
      content: selectedData[0]['chassisNumber'],
      icon: <IconGarage className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Machine Number',
      content: selectedData[0]['machineNumber'],
      icon: <IconBallot className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Dealer Seller',
      content: selectedData[0]['sellerPlace']['name'],
      subContent: selectedData[0]['sellerPlace']['address'],
      icon: <IconPlace className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Dealer Installation',
      content: selectedData[0]['installationPlace']['name'],
      subContent: selectedData[0]['installationPlace']['address'],
      icon: <IconPinDrop className={classes['iconForText']}/>,
    },
    {
      subtitle: 'PIC Name',
      content: selectedData[0]['picName'],
      icon: <IconContacts className={classes['iconForText']}/>,
    },
    {
      subtitle: 'PIC Phone Number',
      content: selectedData[0]['picPhoneNumber'],
      icon: <IconCall className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Installation Schedule',
      content: selectedData[0]['installmentDate'],
      icon: <IconInsertInvitation className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Admin Name',
      content: selectedData[0]['adminName'],
      icon: <IconPersonPin className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Timestamp Created',
      content: selectedData[0]['createdAt'],
      icon: <IconDateRange className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Timestamp Updated',
      content: selectedData[0]['updatedAt'],
      icon: <IconDateRange className={classes['iconForText']}/>,
    },
    {
      subtitle: 'UUID',
      content: selectedData[0]['uuid'],
      icon: <IconReceipt className={classes['iconForText']}/>,
    },
  ]

  return (
    <div className={classes['rightInfoMainSectionRoot']}>
      {/* INSTRUCTION TEXT */}
      {selectedRowList['length'] === 0 &&
      <Typography
        variant='subtitle2'
        className={classes['instructionText']}
      >
        Select an entry from the list
      </Typography>}

      {/* COUNT */}
      {selectedRowList['length'] > 1 &&
      <Typography
        variant='subtitle2'
        className={classes['blackerText']}
      >
        {`${selectedRowList['length']} record entries chosen`}
      </Typography>}

      {/* MAIN CONTENT TITLE CONTAINER */}
      {selectedRowList['length'] === 1 &&
      <div className={classes['mainContentTitleContainer']}>
        {/* TITLE */}
        <Typography
          variant='subtitle1'
          className={classes['title']}
        >
          Main Section
        </Typography>

        {/* ICON EXPAND */}
        <IconButton
          onClick={() => setIsMainContentExpand(current => !current)}
          className={classes['iconExpandContainer']}
        >
          <img 
            src={IconExpand}
            alt=''
            className={
              isMainContentExpand ?
              classes['iconCollapse'] :
              classes['iconExpand']
            }
          />
        </IconButton>
      </div>}

      {/* SINGLE ITEM */}
      {selectedRowList['length'] === 1 &&
      <Collapse
        in={isMainContentExpand} 
        timeout='auto' 
        unmountOnExit
      >
        {/* NAME, PHONE, EMAIL, AND CREATED DATE */}
        {selectedData && data.map((item, index) => (
          <div 
            key={index}
            className={classes['iconAndTextContainer']}
          >
            {/* ICON */}
            {item['icon']}

            <div>
              {/* SUBTITLE */}
              <Typography
                variant='subtitle2'
                className={classes['subtitle']}
              >
                {item['subtitle']}
              </Typography>

              {/* CONTENT */}
              <Typography
                variant='subtitle2'
                className={classes['blackerText']}
              >
                {item['content']}
              </Typography>

              {/* SUBCONTENT */}
              {item['subContent'] &&
              <Typography
                variant='subtitle2'
                className={classes['lighterText']}
              >
                {item['subContent']}
              </Typography>}
            </div>
          </div>
        ))}
      </Collapse>}
    </div>
  )
}

export default RightInfoMainSection
