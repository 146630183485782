// UTILS
import {
  getStringDateFromStringDate,
  getStringClockFromStringDate,
} from 'utils/formatStringDate'

export const getSalesOrderListApi = async (inputToken) => {
  // const TAG = 'getSalesOrderListApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/sales-order/list`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
    },
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}

export const formatGetSalesOrderListApi = async (inputToken) => {
  const rawListData = await getSalesOrderListApi(inputToken)

  if(rawListData['error']) return rawListData
  else {
    const output = rawListData.map(item => {
      return {
        ...item,
        installmentDate: `${getStringDateFromStringDate(item['installmentDate'])} ${getStringClockFromStringDate(item['installmentDate'])}`,
        createdAt: `${getStringDateFromStringDate(item['createdAt'])} ${getStringClockFromStringDate(item['createdAt'])}`,
        updatedAt: `${getStringDateFromStringDate(item['updatedAt'])} ${getStringClockFromStringDate(item['updatedAt'])}`,
      }
    })
    return output
  }
}