export const postNewDeviceApi = async (
  inputToken,
  inputImei,
  inputSimNumber,
  imputSerialNumber,
) => {
  // const TAG = 'postNewDeviceApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/device/create`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'imei': inputImei,
      'simNumber': inputSimNumber,
      'snNumber': imputSerialNumber,
    }),
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}