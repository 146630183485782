import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PageAccessoriesContext } from 'contexts/PageAccessoriesContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// SERVICES
import { getAccessoriesListApi } from 'services/accessories/getAccessoriesListApi'
import { postNewAccessoriesApi } from 'services/accessories/postNewAccessoriesApi'
import { putUpdateAccessoryApi } from 'services/accessories/putUpdateAccessoryApi'

// STYLES
import useStyles from './dialogAddOrEditAccessoryUseStyles'

const DialogAddOrEditAccessory = (props) => {
  const { 
    type,
    dialogAddOrEditAccessory, 
    setDialogAddOrEditAccessory,
  } = props

  const { currentUser } = useContext(CurrentUserContext)

  const { 
    changeTableData,
    changeIsLoading,
  } = useContext(PageAccessoriesContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const [ accessoriesName, setAccessoriesName ] = useState(type === 'edit' ? dialogAddOrEditAccessory['row']['name'] : type === 'add' ? '' : '')
  const [ errorMessage, setErrorMessage ] = useState(null)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddOrEditAccessory(false)
    }
  }

  const fetchPostNewAccessoriesApi = async () => {
    changeIsLoading(true)

    let response
    if(type === 'edit') {
      response = await putUpdateAccessoryApi(
        currentUser['accessToken'],
        dialogAddOrEditAccessory['id'],
        accessoriesName,
      )
    }
    else if(type === 'add') {
      response = await postNewAccessoriesApi(
        currentUser['accessToken'],
        accessoriesName,
      )
    }

    if(response['error']) {
      changeToast({
        open: true,
        message: response['error'], 
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: `Successfully ${type}ing an accessory`, 
        severity: 'success',
      })
      
      const data = await getAccessoriesListApi(currentUser['accessToken'])
      // console.log(data)
      if(data['error']) {
        changeToast({
          open: true,
          message: data['error'], 
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false)
    setDialogAddOrEditAccessory(false)
  }

  const onFinishButtonIsClicked = () => {
    if(accessoriesName === '') {
      setErrorMessage('Accessories name must not be empty')
    }
    else {
      fetchPostNewAccessoriesApi()
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [accessoriesName]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogAddOrEditAccessory)}
      onClose={handleClose}
      scroll={'paper'}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        {type === 'add' && 'Add New Accessory'}
        {type === 'edit' && 'Edit Accessory'}
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* ACCESSORIES NAME TEXT FIELD */}
        <CustomTextFieldSmall
          className={classes['textField']}
          label='Accessories Name'
          value={accessoriesName}
          onChange={(event) => setAccessoriesName(event['target']['value'])}
        />
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => setDialogAddOrEditAccessory(null)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* OK BUTTON */}
        <Button onClick={onFinishButtonIsClicked}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogAddOrEditAccessory
