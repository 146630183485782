import React, { useState, useContext } from 'react'

// COMPONENTS
import AddButton from 'components/AddButton/AddButton'
import SearchField from 'components/SearchField/SearchField'
import ToggleRightInfo from 'components/ToggleRightInfo/ToggleRightInfo'
import DialogAddOrEditSalesOrder from '../DialogAddOrEditSalesOrder/DialogAddOrEditSalesOrder'

// CONTEXTS
import { PageSalesOrderContext } from 'contexts/PageSalesOrderContext'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './salesOrderHeaderUseStyles'

const SalesOrderHeader = () => {
  const { 
    search, 
    changeSearch,
    isRightInfoOpen, 
    changeIsRightInfoOpen,
  } = useContext(PageSalesOrderContext)

  const classes = useStyles()

  const [ dialogAddOrEditSalesOrder, setDialogAddOrEditSalesOrder ] = useState(false)

  return (
    <div className={classes['salesOrderHeaderRoot']}>
      {/* ADD BUTTON */}
      <AddButton
        className={classes['addIconButton']}
        onClick={() => setDialogAddOrEditSalesOrder(true)}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes['title']}
      >
        Sales Order
      </Typography>

      {/* SEARCH TEXT FIELD */}
      <SearchField
        value={search}
        onChange={(event) => changeSearch(event['target']['value'])}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={
          isRightInfoOpen ?
          `${classes['information']} ${classes['informationVisible']}` :
          classes['information']
        }
      >
        Information
      </Typography>

      {/* TOGGLE HISTORY */}
      <ToggleRightInfo
        isRightInfoOpen={isRightInfoOpen}
        onClick={() => changeIsRightInfoOpen(current => !current)}
      />

      {/* DIALOG ADD OR EDIT SALES ORDER */}
      <DialogAddOrEditSalesOrder
        type='add'
        dialogAddOrEditSalesOrder={dialogAddOrEditSalesOrder}
        setDialogAddOrEditSalesOrder={setDialogAddOrEditSalesOrder}
      />
    </div>
  )
}

export default SalesOrderHeader
