import React from 'react'
import { useLocation } from 'react-router-dom'

// COMPONENTS
import Sidebar from 'components/Sidebar/Sidebar'

// CONTEXTS
import { PageDevicesContextProvider } from 'contexts/PageDevicesContext'
import { PagePlacesContextProvider } from 'contexts/PagePlacesContext'
import { PageAccessoriesContextProvider } from 'contexts/PageAccessoriesContext'
import { PageCarModelContextProvider } from 'contexts/PageCarModelContext'
import { PageStaffContextProvider } from 'contexts/PageStaffContext'
import { PageStockOutContextProvider } from 'contexts/PageStockOutContext'
import { PageSalesOrderContextProvider } from 'contexts/PageSalesOrderContext'

// PAGES
import Dashboard from 'pages/Dashboard/Dashboard'
import Devices from 'pages/Devices/Devices'
import Accessories from 'pages/Accessories/Accessories'
import CarModel from 'pages/CarModel/CarModel'
import Places from 'pages/Places/Places'
import Staff from 'pages/Staff/Staff'
import StockOut from 'pages/StockOut/StockOut'
import SalesOrder from 'pages/SalesOrder/SalesOrder'

// STYLES
import useStyles from './pageWithSidebarUseStyles'

const PageWithSidebar = () => {
  let location = useLocation()
  location = location['pathname']

  const classes = useStyles()

  const selectedPage = () => {
    if(location === '/dashboard') {
      return(
        <Dashboard/>
      )
    }
    else if(location === '/items/devices') {
      return(
        <PageDevicesContextProvider>
          <Devices/>
        </PageDevicesContextProvider>
      )
    }
    else if(location === '/items/accessories') {
      return(
        <PageAccessoriesContextProvider>
          <Accessories/>
        </PageAccessoriesContextProvider>
      )
    }
    else if(location === '/items/car-model') {
      return(
        <PageCarModelContextProvider>
          <CarModel/>
        </PageCarModelContextProvider>
      )
    }
    else if(location === '/places') {
      return(
        <PagePlacesContextProvider>
          <Places/>
        </PagePlacesContextProvider>
      )
    }
    else if(location === '/staff') {
      return(
        <PageStaffContextProvider>
          <Staff/>
        </PageStaffContextProvider>
      )
    }
    else if(location === '/stock-out') {
      return(
        <PageStockOutContextProvider>
          <StockOut/>
        </PageStockOutContextProvider>
      )
    }
    else if(location === '/sales-order') {
      return(
        <PageSalesOrderContextProvider>
          <SalesOrder/>
        </PageSalesOrderContextProvider>
      )
    }
  }

  return (
    <div className={classes['pageWithSidebarContainer']}>
      <Sidebar/>

      {selectedPage()}
    </div>
  )
}

export default PageWithSidebar
