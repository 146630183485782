import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PageDevicesContext } from 'contexts/PageDevicesContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// SERVICES
import { putUpdateDeviceApi } from 'services/devices/putUpdateDeviceApi'
import { formatGetDevicesListApi } from 'services/devices/getDevicesListApi'

// STYLES
import useStyles from './dialogEditDeviceUseStyles'

const EditDialog = (props) => {
  const { 
    dialogEdit, 
    setDialogEdit,
  } = props

  const { currentUser } = useContext(CurrentUserContext)
  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const {
    changeIsLoading,
    changeTableData,
  } = useContext(PageDevicesContext)
  
  const [ simNumber, setSimNumber ] = useState(dialogEdit['row']['simNumber'])
  const [ imei, setImei ] = useState(dialogEdit['row']['imei'])
  // const [ serialNumber, setSerialNumber ] = useState(dialogEdit['row']['serialNumber'])
  const [ errorMessage, setErrorMessage ] = useState(null)

  const fetchPutUpdateDeviceApi = async () => {
    changeIsLoading(true)

    const response = await putUpdateDeviceApi(
      currentUser['accessToken'],
      dialogEdit['row']['id'],
      simNumber,
      imei,
    )
    if(response['error']) {
      changeToast({
        open: true,
        message: response['error'], 
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: 'Sucessfully editing a new device', 
        severity: 'success',
      })

      const data = await formatGetDevicesListApi(currentUser['accessToken'])
      // console.log(data)
      if(data['error']) {
        changeToast({
          open: true,
          message: data['error'], 
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false) 
    setDialogEdit(null)
  }

  const onSaveButtonIsClicked = async () => {
    if(simNumber === '' || imei === '') {
      setErrorMessage('Please fill all fields')
    }
    else {
      fetchPutUpdateDeviceApi()
    }
  }

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogEdit(false)
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [simNumber, imei]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogEdit)}
      onClose={handleClose}
      scroll={'paper'}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Edit
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* SIM NUMBER TEXT FIELD */}
        <CustomTextFieldSmall
          className={classes['textField']}
          label='Sim Number'
          value={simNumber}
          onChange={(event) => setSimNumber(event['target']['value'])}
        />

        {/* IMEI TEXT FIELD */}
        <CustomTextFieldSmall
          className={classes['textField']}
          label='IMEI'
          value={imei}
          onChange={(event) => setImei(event['target']['value'])}
        />

        {/* SERIAL NUMBER FIELD */}
        {/* <CustomTextFieldSmall
          className={classes['textField']}
          label='Serial Number'
          value={serialNumber}
          onChange={(event) => setSerialNumber(event['target']['value'])}
        /> */}
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => setDialogEdit(null)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* OK BUTTON */}
        <Button onClick={onSaveButtonIsClicked}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default EditDialog
