const carModelData = [
  {
    id: 1,
    name: 'Car Model 1'
  },
  {
    id: 2,
    name: 'Car Model 2'
  },
  {
    id: 3,
    name: 'Car Model 3'
  },
  {
    id: 4,
    name: 'Car Model 4'
  },
]

export default carModelData