export const putUpdateAccessoryApi = async (
  inputToken,
  inputItemId,
  inputNewName,
) => {
  // const TAG = 'putUpdateAccessoryApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/item/update`
  
  const response = await fetch(completeUrl, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'itemId': inputItemId,
      'newName': inputNewName,
      'newType': 'ACCESSORY',
    }),
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}