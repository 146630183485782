import React, { useState, useEffect, useContext, useRef } from 'react'

// CONTEXTS
import { PageCarModelContext } from 'contexts/PageCarModelContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'

const CarModelTable = () => {
  const { 
    search,
    tableData,
    selectedRowList,
    changeSelectedRowList, 
  } = useContext(PageCarModelContext)

  const [ pageSize, setPageSize ] = useState(10)
  const [ isHovered, setIsHovered ] = useState(null)
  const [ filteredData, setFilteredData ] = useState([]) 

  const cellClickRef = useRef(null)

  const columns = [
    {
      field: 'name',
      headerName: 'Car Model Name',
      flex: 1,
      minWidth: 300,
    },
  ]

  useEffect(() => {
    if(search !== '') {
      const filteredData = tableData.filter(item =>
        item['name'].toLowerCase().includes(search.toLowerCase())
      )
      setFilteredData(filteredData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <CustomDataGrid 
        rows={search === '' ? tableData : filteredData}
        columns={columns}
        onRowEnter={(params, event) => setIsHovered(params['row']['id'])}
        onRowLeave={(params, event) => setIsHovered(null)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        selectionModel={selectedRowList}
        onCellClick={() => cellClickRef['current'] = true}
        onSelectionModelChange={(model) =>
          onSelectionModelChange(
            model, 
            cellClickRef, 
            selectedRowList, 
            changeSelectedRowList,
            null,
          )
        }
      />
    </>
  )
}

export default CarModelTable
