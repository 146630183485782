import React, { useState, useEffect, useContext, useRef } from 'react'

// CONTEXTS
import { PageStockOutContext } from 'contexts/PageStockOutContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'

// MATERIAL UI ICONS
import IconDelete from '@material-ui/icons/Delete'
import IconPencil from '@material-ui/icons/Create'

// STYLES
import useStyles from './stockOutTableUseStyles'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'

const StaffTable = () => {
  const { 
    search,
    tableData,
    selectedRowList,
    changeSelectedRowList,
    changeIsRightInfoOpen,
  } = useContext(PageStockOutContext)

  const classes = useStyles()

  const [ pageSize, setPageSize ] = useState(10)
  const [ isHovered, setIsHovered ] = useState(null)
  const [ filteredData, setFilteredData ] = useState([]) 

  const cellClickRef = useRef(null)

  const socketColumns = tableData['length'] > 0 ?
  tableData[0]['formattedSockets'].map(item => {
    return {
      field: item['field'],
      headerName: item['name'],
      flex: 1,
      minWidth: 200,
    }
  }) :
  []

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      minWidth: 175,
    },
    {
      field: 'salesPoint',
      headerName: 'Sales Point',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'totalImei',
      headerName: 'Total IMEI',
      flex: 1,
      minWidth: 150,
    },
    ...socketColumns,
    {
      field: 'uuid',
      headerName: 'UUID',
      flex: 1,
      minWidth: 150,
    },
    {
      field: '',
      headerName: '',
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params['id'] === isHovered &&
        <div className={classes['actionContainer']}>
          <IconButton>
            <IconPencil className={classes['actionIcon']}/>
          </IconButton>
          <IconButton>
            <IconDelete className={classes['actionIcon']}/>
          </IconButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if(search !== '') {
      const filteredData = tableData.filter(item =>
        item['uuid'].toString().toLowerCase().includes(search.toLowerCase()) ||
        item['date'].toLowerCase().includes(search.toLowerCase()) ||
        item['salesPoint'].toLowerCase().includes(search.toLowerCase()) ||
        item['totalImei'].toString().toLowerCase().includes(search.toLowerCase())
      )
      setFilteredData(filteredData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <CustomDataGrid 
      rows={search === '' ? tableData : filteredData}
      columns={columns}
      onRowEnter={(params, event) => setIsHovered(params['row']['id'])}
      onRowLeave={(params, event) => setIsHovered(null)}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      selectionModel={selectedRowList}
      onCellClick={() => cellClickRef['current'] = true}
      onSelectionModelChange={(model) =>
        onSelectionModelChange(
          model, 
          cellClickRef, 
          selectedRowList, 
          changeSelectedRowList,
          changeIsRightInfoOpen,
        )
      }
    />
  )
}

export default StaffTable
