import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginBottom: 25,
  },
  textField: {
    width: '100%',
    marginBottom: 25,
  },
  datePickerTextField: {
    '& .MuiInputBase-input': {
      fontSize: 14,
      cursor: 'pointer',
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
    },
  },
  okText: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
  cancelText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
}))

export default useStyles