// MATERIAL UI CORES
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

const CustomTextFieldSmall = withStyles({
  root: {
    backgroundColor: 'white',
    '& .MuiInputBase-input': {
      font: 'inherit',
      fontSize: 14,
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
    },
    '& input[type=number]': {
			'-moz-appearance': 'textfield'
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0
		},
  },
})(TextField)

const CustomTextFieldSmallWithProps = (props) => {
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData
    const pastedData = parseFloat(clipboardData.getData('text'))

    if (pastedData < 0) {
      e.preventDefault()
    }
  }

  return(
    <CustomTextFieldSmall
      {...props}
      min='0'
      onWheel={(e) => e.target.blur()}
      onPaste={preventPasteNegative}
      onKeyPress={preventMinus}
    />
  )
}

export default CustomTextFieldSmallWithProps