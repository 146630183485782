import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  pageListContainer: {
    width: '100%',
  },
  parentItemContainer: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: '13px 20px 13px 25px',
    height: 48,
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      backgroundColor: colors['slategray'],
    },
  },
  selectedParentItem: {
    backgroundColor: colors['blackDarker'],
  },
  pageItemIcon: {
    marginRight: 15,
    width: 20,
  },
  '@keyframes pageItemAnimation': {
    '0%': {
      visibility: 'hidden',
    },
    '99%': {
      visibility: 'hidden',
    },
    '100%': {
      visibility: 'visible',
    },
  },
  pageItemName: {
    color: colors['darkgray'],
    animation: '$pageItemAnimation 0.2s linear',
  },
  selectedPageItemName: {
    color: 'white',
    animation: '$pageItemAnimation 0.2s linear',
  },
  iconExpand: {
    marginLeft: 'auto',
    width: 6,
    transform: 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out',
    animation: '$pageItemAnimation 0.2s linear',
  },
  iconCollapse: {
    marginLeft: 'auto',
    width: 6,
    transform: 'rotate(90deg)',
    transition: 'transform 0.25s ease-in-out',
    animation: '$pageItemAnimation 0.2s linear',
  },
  parentAndChildrenOnSidebarIsClosed: {
    height: 'fit-content',
    width: 150,
    backgroundColor: colors['black'],
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: -150,
  },
  childrenItemContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0px 10px 25px',
    height: 48,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors['slategray'],
    },
  },
  childrenCirlce: {
    backgroundColor: colors['darkgray'],
    width: 5,
    height: 5,
    borderRadius: '50%',
    marginLeft: 15,
    marginRight: 15,
  },
  childrenCirlceOnSidebarIsClosed: {
    marginLeft: 0,
  },
  selectedChildrenCirlce: {
    backgroundColor: colors['dodgerblue'],
  },
  childrenName: {
    color: colors['darkgray'],
  },
}))

export default useStyles