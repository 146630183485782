// MATERIAL UI CORES
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginBottom: 25,
  },
  iconAndTextFieldContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 25,
    width: '100%',
  },
  icon: {
    marginRight: 15,
    color: colors['slategray'],
  },
  textField: {
    width: '100%',
  },
  okText: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
  cancelText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
}))

export default useStyles