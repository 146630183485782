import React, { useState, useEffect, useContext, useRef } from 'react'

// COMPONENTS
import DialogDeleteSalesOrder from '../DialogDeleteSalesOder/DialogDeleteSalesOrder'
import DialogAddOrEditSalesOrder from '../DialogAddOrEditSalesOrder/DialogAddOrEditSalesOrder'

// CONTEXTS
import { PageSalesOrderContext } from 'contexts/PageSalesOrderContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconDelete from '@material-ui/icons/Delete'
import IconPencil from '@material-ui/icons/Create'

// STYLES
import useStyles from './salesOrderTableUseStyles'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'

const SalesOrderTable = () => {
  const {
    search,
    selectedRowList,
    changeSelectedRowList,
    changeIsRightInfoOpen,
    tableData,
  } = useContext(PageSalesOrderContext)

  const classes  = useStyles()

  const [ pageSize, setPageSize ] = useState(10)
  const [ filteredData, setFilteredData ] = useState([])
  const [ hovered, setHovered ] = useState(null)
  const [ dialogDeleteSalesOrder, setDialogDeleteSalesOrder ] = useState(null)
  const [ dialogAddOrEditSalesOrder, setDialogAddOrEditSalesOrder ] = useState(false)

  const cellClickRef = useRef(null)

  const columns = [
    {
      field: 'customerName',
      headerName: 'Custom Name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'contractNumber',
      headerName: 'Contract No.',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'bpkbCustomerName',
      headerName: 'BPKB Name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'carModel',
      headerName: 'Car Model',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {params['value']['name']}
        </Typography>
      ),
    },
    {
      field: 'chassisNumber',
      headerName: 'Chassis No.',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'machineNumber',
      headerName: 'Machine No.',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'sellerPlace',
      headerName: 'Dealer Seller',
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <div>
          <Typography
            variant='subtitle2'
            className={classes['defaultText']}
          >
            {params['value']['name']}
          </Typography>
          <Typography
            variant='subtitle2'
            className={classes['ligtherText']}
          >
            {params['value']['address']}
          </Typography>
        </div>
      ),
    },
    {
      field: 'installationPlace',
      headerName: 'Dealer Installation',
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <div>
          <Typography
            variant='subtitle2'
            className={classes['defaultText']}
          >
            {params['value']['name']}
          </Typography>
          <Typography
            variant='subtitle2'
            className={classes['ligtherText']}
          >
            {params['value']['address']}
          </Typography>
        </div>
      ),
    },
    {
      field: 'picName',
      headerName: 'PIC Name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'picPhoneNumber',
      headerName: 'PIC Phone Number',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'installmentDate',
      headerName: 'Installation Schedule',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'adminName',
      headerName: 'Admin Name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'createdAt',
      headerName: 'Timestamp Created',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'updatedAt',
      headerName: 'Timestamp Updated',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'uuid',
      headerName: 'UUID',
      flex: 1,
      minWidth: 300,
    },
    {
      field: '',
      headerName: '',
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params['id'] === hovered &&
        <div className={classes['actionContainer']}>
          <IconButton onClick={() => setDialogAddOrEditSalesOrder(params)}>
            <IconPencil className={classes['actionIcon']}/>
          </IconButton>
          <IconButton onClick={() => setDialogDeleteSalesOrder(params)}>
            <IconDelete className={classes['actionIcon']}/>
          </IconButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if(search !== '') {
      const filteredData = tableData.filter(item => 
        item['customerName'].toLowerCase().includes(search.toLowerCase()) ||
        item['contractNumber'].toString().toLowerCase().includes(search.toLowerCase()) ||
        item['bpkbCustomerName'].toLowerCase().includes(search.toLowerCase()) ||
        item['carModel']['name'].toLowerCase().includes(search.toLowerCase()) ||
        item['chassisNumber'].toLowerCase().includes(search.toLowerCase()) ||
        item['machineNumber'].toLowerCase().includes(search.toLowerCase()) ||
        item['sellerPlace']['name'].toLowerCase().includes(search.toLowerCase()) ||
        item['sellerPlace']['address'].toLowerCase().includes(search.toLowerCase()) ||
        item['installationPlace']['name'].toLowerCase().includes(search.toLowerCase()) ||
        item['installationPlace']['address'].toLowerCase().includes(search.toLowerCase()) ||
        item['picName'].toLowerCase().includes(search.toLowerCase()) ||
        item['picPhoneNumber'].toLowerCase().includes(search.toLowerCase()) ||
        item['installmentDate'].toLowerCase().includes(search.toLowerCase()) ||
        item['adminName'].toLowerCase().includes(search.toLowerCase()) ||
        item['createdAt'].toLowerCase().includes(search.toLowerCase()) ||
        item['updatedAt'].toLowerCase().includes(search.toLowerCase()) ||
        item['uuid'].toLowerCase().includes(search.toLowerCase())
      )
      setFilteredData(filteredData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* DATA GRID */}
      <CustomDataGrid 
        rows={search === '' ? tableData : filteredData} 
        columns={columns}
        onRowEnter={(params, event) => setHovered(params['row']['id'])}
        onRowLeave={(params, event) => setHovered(null)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        selectionModel={selectedRowList}
        onCellClick={() => cellClickRef['current'] = true}
        onSelectionModelChange={(model) =>
          onSelectionModelChange(
            model, 
            cellClickRef, 
            selectedRowList, 
            changeSelectedRowList,
            changeIsRightInfoOpen,
          )
        }
      />

      {/* DIALOG ADD OR EDIT SALES ORDER */}
      {dialogAddOrEditSalesOrder &&
      <DialogAddOrEditSalesOrder
        type='edit'
        dialogAddOrEditSalesOrder={dialogAddOrEditSalesOrder}
        setDialogAddOrEditSalesOrder={setDialogAddOrEditSalesOrder}
      />}

      {/* DIALOG DELETE */}
      <DialogDeleteSalesOrder
        dialogDeleteSalesOrder={dialogDeleteSalesOrder}
        setDialogDeleteSalesOrder={setDialogDeleteSalesOrder}
      />
    </>
  )
}

export default SalesOrderTable
