// MATERIAL UI CORES
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const CustomDialogTitle = withStyles((theme) => ({
  root: {
    color: colors['dodgerblue'],
    fontWeight: 600,
    padding: '30px 40px',
  },
}))(DialogTitle)

export default CustomDialogTitle
