export const getPlacesListApi = async (inputToken) => {
  // const TAG = 'getPlacesListApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/place/list`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${inputToken}`
    },
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}