import React, { useState, useContext } from 'react'

// CONTEXTS
import { PageStockOutContext } from 'contexts/PageStockOutContext'

// IMAGES
import IconExpand from 'images/icons/staff_expand.svg'

// MATERIAL UI CORES
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconInsertInvitation from '@material-ui/icons/InsertInvitation'
import IconLabel from '@material-ui/icons/Label'
import IconMore from '@material-ui/icons/More'
import IconReceipt from '@material-ui/icons/Receipt'
import IconWhereToVote from '@material-ui/icons/WhereToVote'

// STYLES
import useStyles from './mainSectionUseStyles'

const MainSection = () => {
  const classes = useStyles()

  const { 
    selectedRowList, 
    tableData, 
  } = useContext(PageStockOutContext)

  const [ isMainContentExpand, setIsMainContentExpand ] = useState(false)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  const socketsInformation = selectedData['length'] > 0 &&
  selectedData[0]['formattedSockets'].map(item => {
    return {
      subtitle: item['name'],
      content: item['quantity'],
      icon: <IconLabel className={classes['iconForText']}/>,
    }
  })

  const data = selectedData['length'] > 0 && [
    {
      subtitle: 'Date',
      content: selectedData[0]['date'],
      icon: <IconInsertInvitation className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Sales Point',
      content: selectedData[0]['salesPoint'],
      icon: <IconWhereToVote className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Total IMEI',
      content: selectedData[0]['totalImei'],
      icon: <IconMore className={classes['iconForText']}/>,
    },
    ...socketsInformation,
    {
      subtitle: 'UUID',
      content: selectedData[0]['uuid'],
      icon: <IconReceipt className={classes['iconForText']}/>,
    },
  ]
  
  return (
    <div className={classes['mainSectionRoot']}>
      {/* INSTRUCTION TEXT */}
      {selectedRowList['length'] === 0 &&
      <Typography
        variant='subtitle2'
        className={classes['instructionText']}
      >
        Select an entry from the list
      </Typography>}

      {/* MAIN CONTENT TITLE CONTAINER */}
      {selectedRowList['length'] === 1 &&
      <div className={classes['mainContentTitleContainer']}>
        {/* TITLE */}
        <Typography
          variant='subtitle1'
          className={classes['title']}
        >
          {`Main Section - ${selectedData[0]['uuid']}`}
        </Typography>

        {/* ICON EXPAND */}
        <IconButton
          onClick={() => setIsMainContentExpand(current => !current)}
          className={classes['iconExpandContainer']}
        >
          <img 
            src={IconExpand}
            alt=''
            className={
              isMainContentExpand ?
              classes['iconCollapse'] :
              classes['iconExpand']
            }
          />
        </IconButton>
      </div>}

      {/* COUNT */}
      {selectedRowList['length'] > 1 &&
      <Typography
        variant='subtitle2'
        className={classes['blackerText']}
      >
        {`${selectedRowList['length']} record entries chosen`}
      </Typography>}

      {/* SINGLE ITEM */}
      {selectedRowList['length'] === 1 &&
      <Collapse
        in={isMainContentExpand} 
        timeout='auto' 
        unmountOnExit
      >
        {/* NAME, PHONE, EMAIL, AND CREATED DATE */}
        {selectedData && data.map((item, index) => (
          <div 
            key={index}
            className={classes['iconAndTextContainer']}
          >
            {/* ICON */}
            {item['icon']}

            <div>
              {/* SUBTITLE */}
              <Typography
                variant='subtitle2'
                className={classes['subtitle']}
              >
                {item['subtitle']}
              </Typography>

              {/* CONTENT */}
              <Typography
                variant='subtitle2'
                className={classes['blackerText']}
              >
                {item['content']}
              </Typography>
            </div>
          </div>
        ))}
      </Collapse>}
    </div>
  )
}

export default MainSection
