import React, { createContext, useState } from 'react'

const PageSalesOrderContext = createContext()

const PageSalesOrderContextProvider = (props) => {
  // TYPE = STRING
  const [ search, setSearch ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)

  // TYPE = BOOLEAN
  const [ isRightInfoOpen, setIsRightInfoOpen ] = useState(false)

  // TYPE = ARRAY
  const [ selectedRowList, setSelectedRowList ] = useState([])
  const [ tableData, setTableData ] = useState([])

  const changeSearch = (inputSearch) => {
    setSearch(inputSearch)
  }

  const changeIsLoading = (inputState) => {
    setIsLoading(inputState)
  }

  const changeIsRightInfoOpen = (inputState) => {
    setIsRightInfoOpen(inputState)
  }

  const changeSelectedRowList = (inputRowList) => {
    setSelectedRowList(inputRowList)
  }

  const changeTableData = (inputData) => {
    setTableData(inputData)
  }

  return (
    <PageSalesOrderContext.Provider
      value={{
        search, changeSearch,
        isLoading, changeIsLoading,
        isRightInfoOpen, changeIsRightInfoOpen,
        selectedRowList, changeSelectedRowList,
        tableData, changeTableData,
      }}
    >
      {props['children']}
    </PageSalesOrderContext.Provider>
  )
}

export { PageSalesOrderContextProvider, PageSalesOrderContext }