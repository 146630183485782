import React, { useContext } from 'react'

// CONTEXTS
// import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PageStaffContext } from 'contexts/PageStaffContext'
// import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// SERVICES
// import { deleteStaffApi } from 'services/staffs/deleteStaffApi'
// import { formatGetStaffListApi } from 'services/staffs/getStaffListApi'

// STYLES
import useStyles from './dialogDisableStaffUseStyles'

const DialogDisableStaff = (props) => {
  const {
    dialogDisable, 
    setDialogDisable,
  } = props

  // const { currentUser } = useContext(CurrentUserContext) 

  const { 
    changeIsLoading,
    // changeTableData,
  } = useContext(PageStaffContext)

  // const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogDisable(false)
    }
  }

  const onDisableButtonIsClicked = async () => {
    changeIsLoading(true)
    
    // const response = await deleteStaffApi(currentUser['accessToken'], dialogDisable['id'])
    // // console.log(response)
    // if(response['error']) {
    //   changeToast({
    //     open: true,
    //     message: response['error'], 
    //     severity: 'error',
    //   })
    // }
    // else {
    //   changeToast({
    //     open: true,
    //     message: 'Successfully disabling a staff', 
    //     severity: 'success',
    //   })

    //   const data = await formatGetStaffListApi(currentUser['accessToken'])
    //   if(data['error']) {
    //     changeToast({
    //       open: true,
    //       message: data['error'], 
    //       severity: 'error',
    //     })
    //   }
    //   else {
    //     changeTableData(data)
    //   }
    // }

    changeIsLoading(false)
    setDialogDisable(false)
  }

  return (
    <CustomDialog 
      open={Boolean(dialogDisable)}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Disable Staff
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        <Typography variant='subtitle2'>
          Are you sure you want to disable this staff?
        </Typography>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CLOSE BUTTON */}
        <Button onClick={() => setDialogDisable(false)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* FINISH BUTTON */}
        <Button onClick={() => onDisableButtonIsClicked()}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Disable
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogDisableStaff
