export const postNewAccessoriesApi = async (
  inputToken,
  inputName,
) => {
  // const TAG = 'postNewAccessoriesApi'

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/item/create`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${inputToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'name': inputName,
    }),
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}